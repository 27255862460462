// const BASE_URI = "https://backoffice.martispizza.ru/api";
// const ECHO_URI = "backoffice.martispizza.ru";
const BASE_URI = "https://test.backoffice.martispizza.ru/api";
const ECHO_URI = "test.backoffice.martispizza.ru";

export { BASE_URI, ECHO_URI };

//BASE_URI
//'https://backoffice.martispizza.ru/api'
//'https://test.backoffice.martispizza.ru/api'
//ECHO_URI
//'backoffice.martispizza.ru'
//'test.backoffice.martispizza.ru'
